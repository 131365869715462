import { HttpContextToken } from "@angular/common/http";

export class Constants {
    //==================================
    // #region ACCESS TOKEN
    //==================================
    public static ACCESS_TOKEN = () => localStorage.getItem('accessToken');
    public static TOKEN_REQUIRED = new HttpContextToken(() => true);
    //==================================
    // #endregion
    //==================================
    //==================================
    // #region PAGINATION
    //==================================
    public static paginationSizes = [5, 10, 20];
    public static paginationSizeDefault = 10;
    //==================================
    // #endregion
    //==================================
}
